import { ViewAnimation } from 'types/view'
import BASE_ANIMATION from './baseView'

const STARTING_VIEW_ANIMATION: ViewAnimation = {
  mobile: {
    ...BASE_ANIMATION.mobile,
    cloudLeft1: {
      animation: {
        default: {
          x: [0, 20],
          y: [0, 5],
        },
        clicked: {},
      },
      transition: {
        duration: 2.15,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
    cloudLeft2: {
      animation: {
        default: {
          x: [0, 20],
          y: [0, 5],
          rotate: [12, 12],
        },
        clicked: {},
      },
      transition: {
        duration: 2.15,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
    cloudsHexagonal: {
      animation: {
        default: { rotate: [0, -60] },
        clicked: {},
      },
      transition: {
        duration: 2.15,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
    cloudRight1: {
      animation: {
        default: {
          x: [0, 20],
          y: [0, -10],
          scale: [1, 1.22],
        },
        clicked: {},
      },
      transition: {
        duration: 2.15,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
    circleLeft: {
      animation: {
        default: {
          y: [0, -30],
        },
        clicked: {},
      },
      transition: {
        duration: 2.15,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
    pickupStationIcon: {
      animation: {
        rotate: [-4, -12],
      },
      transition: {
        duration: 2.15,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
    pickupPointIcon: {
      animation: {
        rotate: [-8, 0],
      },
      transition: {
        duration: 2.15,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
    courierIcon: {
      animation: {
        rotate: [0, 8],
      },
      transition: {
        duration: 2.15,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
  },
  desktop: {
    ...BASE_ANIMATION.desktop,
    cloudLeft1: {
      animation: {
        default: {
          x: [0, 90],
          y: [0, 70],
        },
        clicked: {},
      },
      transition: {
        duration: 2.15,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
    cloudLeft2: {
      animation: {
        default: {
          x: [0, 90],
          y: [0, 70],
          rotate: [15, 15],
        },
        clicked: {},
      },
      transition: {
        duration: 2.15,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
    cloudLeft3: {
      animation: {
        default: {
          scale: [1, 1.15],
          x: [0, -15],
          y: [0, -5],
        },
        clicked: {},
      },
      transition: {
        duration: 2.15,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
    cloudRight1: {
      animation: {
        default: {
          scale: [1, 1.05],
          x: [0, 10],
          y: [0, -5],
        },
        clicked: {},
      },
      transition: {
        duration: 2.15,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
    cloudRight2: {
      animation: {
        default: {
          x: [0, -100],
          y: [0, 70],
        },
        clicked: {},
      },
      transition: {
        duration: 2.15,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
    patternRedRight1: {
      animation: {
        default: {
          x: [0, 35],
          y: [0, -15],
          rotate: [110, 110],
        },
        clicked: {},
      },
      transition: {
        duration: 2.15,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
    pickupStationIcon: {
      animation: {
        rotate: [-4, -12],
      },
      transition: {
        duration: 2.15,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
    pickupPointIcon: {
      animation: {
        rotate: [-8, 0],
      },
      transition: {
        duration: 2.15,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
    courierIcon: {
      animation: {
        rotate: [0, 8],
      },
      transition: {
        duration: 2.15,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
  },
} as const

export default STARTING_VIEW_ANIMATION
