/* eslint-disable */

import * as React from 'react'
import styled, { css } from 'styled-components'
import { motion, useAnimationControls } from 'framer-motion'
import { graphql, useStaticQuery } from 'gatsby'

import GlobalStyles from 'styles/global'
import Cookies from 'components/shared/Cookies'

import CentralViewWrapper from 'components/layout/Views/CentralViewWrapper'
import LazyImage from 'components/shared/lazyImage'

import Icon from 'components/shared/icon'

import cloudCornerIcon1 from 'assets/icons/cloud-corner-1.svg'
import cloudCornerIcon2 from 'assets/icons/cloud-corner-2.svg'
import dpdLogo from 'assets/icons/dpd-logo-2.svg'
import redLinePatternIcon from 'assets/icons/pattern-1.svg'
import whiteLinePatternIcon from 'assets/icons/pattern-2.svg'
import cloudIcon1 from 'assets/icons/cloud-1.svg'
import cloudIcon2 from 'assets/icons/cloud-2.svg'
import cloudIcon3 from 'assets/icons/cloud-3.svg'
import cloudsIcon from 'assets/icons/clouds.svg'
import patternRedIcon1 from 'assets/icons/pattern-6.svg'
import patternRedIcon2 from 'assets/icons/pattern-7.svg'
import patternRedIcon3 from 'assets/icons/pattern-4.svg'
import patternRedIcon4 from 'assets/icons/pattern-8.svg'
import patternRedIcon5 from 'assets/icons/pattern-3.svg'
import patternRedIcon6 from 'assets/icons/pattern-5.svg'
import circleIcon from 'assets/icons/pattern-round.svg'
import circleIconRed from 'assets/icons/pattern-round-red.svg'

import useBreakpoint from 'hooks/useBreakpoint'
import useView from 'hooks/useView'
import useDeliveryTypes from 'hooks/useDeliveryTypes'
import useAnimation from 'hooks/useAnimation'

import { View } from 'types/view'
import { ImageDataLike } from 'gatsby-plugin-image'

type LayoutProps = {
  children: React.ReactNode
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: stretch;
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.primary};
`

const Cloud1 = styled.div`
  position: absolute;
  top: -340px;
  left: -125px;
  z-index: 2;
`

const Cloud2 = styled.div`
  position: absolute;
  top: -360px;
  left: 95px;
  z-index: 2;
`

const Logo = styled.div`
  position: absolute;
  top: 45px;
  left: 65px;
  z-index: 2;
  cursor: pointer;
`
// Layout Icons

const WhiteLinePattern = styled.div<{ currentView: View }>`
  position: absolute;
  z-index: ${({ currentView }) =>
    currentView === 'deliveryBenefitsView' ? '1' : '0'};
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-44%, -56%);
`

const RedLinePattern = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-48%, -51.5%) rotate(-25deg);
`

const Ellipse1 = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -91%);
  width: 700px;
`

const Ellipse2 = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -8%) rotate(150deg);
  width: 590px;
`

const AnimatedItem = styled(motion.div)<{
  isStartingView?: boolean
  isDeliveryView?: boolean
  isChooseDeliveryView?: boolean
  isBenefitsView?: boolean
}>`
  position: absolute;
`

const CloudLeft1 = styled(AnimatedItem)`
  top: 33%;
  left: -35%;

  ${({ theme }) => theme.media.xsm.max} {
    ${({ isStartingView }) =>
      isStartingView &&
      css`
        top: 53%;
        left: 8%;
      `}
    ${({ isDeliveryView }) =>
      isDeliveryView &&
      css`
        top: 40.5%;
        left: 5%;
      `}
      ${({ isBenefitsView }) =>
      isBenefitsView &&
      css`
        display: none;
      `}
      ${({ isChooseDeliveryView }) =>
      isChooseDeliveryView &&
      css`
        top: 40.5%;
        left: 5%;
      `}
  }
`

const CloudLeft2 = styled(AnimatedItem)`
  top: 29.5%;
  left: -44%;
  transform: rotate(10deg);

  ${({ theme }) => theme.media.xsm.max} {
    ${({ isStartingView }) =>
      isStartingView &&
      css`
        top: 50%;
        left: 4%;
      `}

    ${({ isDeliveryView }) =>
      isDeliveryView &&
      css`
        top: 38%;
        left: 1%;
      `}
      ${({ isBenefitsView }) =>
      isBenefitsView &&
      css`
        display: none;
      `}
      ${({ isChooseDeliveryView }) =>
      isChooseDeliveryView &&
      css`
        top: 38%;
        left: 1%;
      `}
  }
`

const CloudLeft3 = styled(AnimatedItem)`
  top: 33%;
  left: -53%;

  ${({ theme }) => theme.media.xsm.max} {
    ${({ isStartingView }) =>
      isStartingView &&
      css`
        display: none;
      `}

    ${({ isDeliveryView }) =>
      isDeliveryView &&
      css`
        display: none;
      `}

      ${({ isBenefitsView }) =>
      isBenefitsView &&
      css`
        display: none;
      `}
  }
`

const CloudLeft4 = styled(AnimatedItem)`
  top: 23%;
  left: -124%;
  opacity: 0;
`

const CloudsHexagonal = styled(AnimatedItem)`
  top: 35%;
  right: -39%;

  ${({ theme }) => theme.media.xsm.max} {
    ${({ isStartingView }) =>
      isStartingView &&
      css`
        top: 63%;
        right: 82%;
      `}
  }
`

const CloudRight1 = styled(AnimatedItem)`
  top: -3%;
  right: -52%;
  transform: rotate(2deg);

  ${({ theme }) => theme.media.xsm.max} {
    ${({ isStartingView }) =>
      isStartingView &&
      css`
        top: 21%;
        right: -22%;
      `}

    ${({ isDeliveryView }) =>
      isDeliveryView &&
      css`
        top: 5%;
        right: -24%;
      `}

      ${({ isBenefitsView }) =>
      isBenefitsView &&
      css`
        display: none;
      `}
  }
`

const CloudRight2 = styled(AnimatedItem)`
  top: 50%;
  right: -64%;
  transform: rotate(4deg);
`

const CircleLeft = styled(AnimatedItem)`
  top: 7%;
  left: -26%;

  ${({ theme }) => theme.media.xsm.max} {
    ${({ isStartingView }) =>
      isStartingView &&
      css`
        left: 90%;
        top: 46%;
      `}
  }
`

const CircleRedLeft = styled(AnimatedItem)`
  top: 58%;
  left: -46%;
`

const CircleRedRight = styled(AnimatedItem)`
  top: 43%;
  right: -136%;
`

const CircleRight = styled(AnimatedItem)`
  top: 80%;
  right: -30%;
`

const PatternRedLeft1 = styled(AnimatedItem)`
  top: 13.5%;
  left: -58%;
`

const PatternRedLeft2 = styled(AnimatedItem)`
  top: 49%;
  left: -104%;
`

const PatternRedRight3 = styled(AnimatedItem)`
  top: 29%;
  right: -18%;
`

const PatternRedLeft4 = styled(AnimatedItem)`
  top: 34%;
  left: -140%;
  transform: rotate(-8deg);
  opacity: 0;
`

const PatternRedLeft5 = styled(AnimatedItem)`
  top: 37%;
  left: -120%;
`

const PatternRedRight1 = styled(AnimatedItem)`
  top: 4%;
  right: -63%;
  transform: rotate(110deg);
`

const PatternRedRight2 = styled(AnimatedItem)`
  top: 24%;
  right: -51%;

  ${({ theme }) => theme.media.xsm.max} {
    ${({ isStartingView }) =>
      isStartingView &&
      css`
        display: none;
      `}
  }
`

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const data = useStaticQuery<{
    ellipseImg?: { childImageSharp?: { gatsbyImageData: ImageDataLike } }
  }>(graphql`
    query EllipseImg {
      ellipseImg: file(name: { eq: "ellipse-1" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
        }
      }
    }
  `)

  if (data?.ellipseImg?.childImageSharp?.gatsbyImageData === undefined) {
    throw new Error('Could not find ellipseImg')
  }

  const { xl, xsm } = useBreakpoint()
  const { currentView, changeView, animateAll } = useView()
  const { resetSelectedDeliveryTypes } = useDeliveryTypes()

  const animation = useAnimation()

  const isStartingView = currentView === 'startingView'
  const isChooseDeliveryView = currentView === 'chooseDeliveryView'
  const isDeliveryView = currentView === 'deliveryView'
  const isBenefitsView = currentView === 'deliveryBenefitsView'

  React.useEffect(() => {
    const layoutHeight = () => {
      const el = document.getElementById('layout-container')
      if (el) {
        el.style.height = window.innerHeight + 'px'
      }
    }
    layoutHeight()
    window.addEventListener('resize', layoutHeight)
  }, [])

  const x = useAnimationControls()

  return (
    <>
      {/* <motion.div
        animate={{
          start: {
            opacity: 0,
          },
        }}
      >asd</motion.div> */}
      <GlobalStyles />
      <Cookies />
      <Container id="layout-container">
        {xl && (
          <>
            <Cloud1>
              <Icon src={cloudCornerIcon1} size={700} alt="cloud-top-left" />
            </Cloud1>
            <Cloud2>
              <Icon src={cloudCornerIcon2} size={600} alt="cloud-top-left" />
            </Cloud2>
            <Logo
              onClick={() => {
                changeView('startingView')
                resetSelectedDeliveryTypes()
              }}
            >
              <Icon src={dpdLogo} size={100} alt="cloud-top-left" />
            </Logo>
          </>
        )}
        <WhiteLinePattern currentView={currentView}>
          <Icon src={whiteLinePatternIcon} size={1700} alt="white-line" />
        </WhiteLinePattern>
        <RedLinePattern>
          <Icon src={redLinePatternIcon} size={2150} alt="red-line" />
        </RedLinePattern>
        <Ellipse1>
          <LazyImage
            src={data.ellipseImg.childImageSharp.gatsbyImageData}
            alt="ellipse-red"
          />
        </Ellipse1>
        <Ellipse2>
          <LazyImage
            src={data.ellipseImg.childImageSharp.gatsbyImageData}
            alt="ellipse-red"
          />
        </Ellipse2>
        <CentralViewWrapper
          padding={xsm ? '65px 30px 50px' : '65px 35px 50px'}
          justifyContent="space-between"
        >
          <>
            <CloudLeft1
              animate={
                animateAll
                  ? isChooseDeliveryView
                    ? animation.cloudLeft1.animation.clicked
                    : animation.cloudLeft1.animation.default
                  : {}
              }
              variants={animation.cloudLeft1.animation}
              transition={animation.cloudLeft1.transition}
              isStartingView={isStartingView}
              isChooseDeliveryView={isChooseDeliveryView}
              isDeliveryView={isDeliveryView}
              isBenefitsView={isBenefitsView}
            >
              <Icon src={cloudIcon2} size={65} alt="cloud" />
            </CloudLeft1>
            <CloudLeft2
              animate={
                animateAll
                  ? isChooseDeliveryView
                    ? animation.cloudLeft2.animation.clicked
                    : animation.cloudLeft2.animation.default
                  : {}
              }
              variants={animation.cloudLeft2.animation}
              transition={animation.cloudLeft2.transition}
              isStartingView={isStartingView}
              isChooseDeliveryView={isChooseDeliveryView}
              isDeliveryView={isDeliveryView}
              isBenefitsView={isBenefitsView}
            >
              <Icon src={cloudIcon2} size={30} alt="cloud" />
            </CloudLeft2>
            <CloudLeft3
              animate={
                animateAll
                  ? isChooseDeliveryView
                    ? animation.cloudLeft3.animation.clicked
                    : animation.cloudLeft3.animation.default
                  : {}
              }
              variants={animation.cloudLeft3.animation}
              transition={animation.cloudLeft3.transition}
              isStartingView={isStartingView}
              isDeliveryView={isChooseDeliveryView || isDeliveryView}
              isBenefitsView={isBenefitsView}
            >
              <Icon src={cloudIcon3} size={250} alt="cloud" />
            </CloudLeft3>
            <CloudLeft4
              animate={
                animateAll
                  ? isChooseDeliveryView
                    ? animation.cloudLeft4.animation.clicked
                    : animation.cloudLeft4.animation.default
                  : {}
              }
              variants={animation.cloudLeft4.animation}
              transition={animation.cloudLeft4.transition}
            >
              <Icon src={cloudIcon3} size={250} alt="cloud" />
            </CloudLeft4>
            <CircleLeft
              animate={
                animateAll
                  ? isChooseDeliveryView
                    ? animation.circleLeft.animation.clicked
                    : animation.circleLeft.animation.default
                  : {}
              }
              variants={animation.circleLeft.animation}
              transition={animation.circleLeft.transition}
              isStartingView={isStartingView}
            >
              <Icon src={circleIcon} size={14} alt="circle" />
            </CircleLeft>
            <CircleRedLeft
              animate={
                animateAll
                  ? isChooseDeliveryView
                    ? animation.circleRedLeft.animation.clicked
                    : animation.circleRedLeft.animation.default
                  : {}
              }
              variants={animation.circleRedLeft.animation}
              transition={animation.circleRedLeft.transition}
            >
              <Icon src={circleIconRed} size={14} alt="circle-red" />
            </CircleRedLeft>
            <CircleRedRight
              animate={
                animateAll
                  ? isChooseDeliveryView
                    ? animation.circleRedRight.animation.clicked
                    : animation.circleRedRight.animation.default
                  : {}
              }
              variants={animation.circleRedRight.animation}
              transition={animation.circleRedRight.transition}
            >
              <Icon src={circleIconRed} size={14} alt="circle-red" />
            </CircleRedRight>
            <PatternRedLeft1
              animate={
                animateAll
                  ? isChooseDeliveryView
                    ? animation.patternRedLeft1.animation.clicked
                    : animation.patternRedLeft1.animation.default
                  : {}
              }
              variants={animation.patternRedLeft1.animation}
              transition={animation.patternRedLeft1.transition}
            >
              <Icon src={patternRedIcon1} size={200} alt="pattern-red" />
            </PatternRedLeft1>
            <PatternRedLeft2
              animate={
                animateAll
                  ? isChooseDeliveryView
                    ? animation.patternRedLeft2.animation.clicked
                    : animation.patternRedLeft2.animation.default
                  : {}
              }
              variants={animation.patternRedLeft2.animation}
              transition={animation.patternRedLeft2.transition}
            >
              <Icon src={patternRedIcon2} size={425} alt="pattern-red" />
            </PatternRedLeft2>
            <PatternRedLeft4>
              <Icon src={patternRedIcon5} size={70} alt="pattern-red" />
            </PatternRedLeft4>
            <PatternRedLeft5
              animate={
                animateAll
                  ? isChooseDeliveryView
                    ? animation.patternRedLeft5.animation.clicked
                    : animation.patternRedLeft5.animation.default
                  : {}
              }
              variants={animation.patternRedLeft5.animation}
              transition={animation.patternRedLeft5.transition}
            >
              <Icon src={patternRedIcon6} size={100} alt="pattern-red" />
            </PatternRedLeft5>
            <CloudRight1
              animate={
                animateAll
                  ? isChooseDeliveryView
                    ? animation.cloudRight1.animation.clicked
                    : animation.cloudRight1.animation.default
                  : {}
              }
              variants={animation.cloudRight1.animation}
              transition={animation.cloudRight1.transition}
              isStartingView={isStartingView}
              isDeliveryView={isChooseDeliveryView || isDeliveryView}
              isBenefitsView={isBenefitsView}
            >
              <Icon src={cloudIcon1} size={xsm ? 270 : 200} alt="cloud" />
            </CloudRight1>
            <CloudRight2
              animate={
                animateAll
                  ? isChooseDeliveryView
                    ? animation.cloudRight2.animation.clicked
                    : animation.cloudRight2.animation.default
                  : {}
              }
              variants={animation.cloudRight2.animation}
              transition={animation.cloudRight2.transition}
            >
              <Icon src={cloudIcon1} size={195} alt="cloud" />
            </CloudRight2>
            <PatternRedRight1
              animate={
                animateAll
                  ? isChooseDeliveryView
                    ? animation.patternRedRight1.animation.clicked
                    : animation.patternRedRight1.animation.default
                  : {}
              }
              variants={animation.patternRedRight1.animation}
              transition={animation.patternRedRight1.transition}
            >
              <Icon src={patternRedIcon5} size={70} alt="pattern-red" />
            </PatternRedRight1>
            <PatternRedRight2
              animate={
                animateAll
                  ? isChooseDeliveryView
                    ? animation.patternRedRight2.animation.clicked
                    : animation.patternRedRight2.animation.default
                  : {}
              }
              variants={animation.patternRedRight2.animation}
              transition={animation.patternRedRight2.transition}
              isStartingView={isStartingView}
            >
              <Icon src={patternRedIcon4} size={250} alt="pattern-red" />
            </PatternRedRight2>
            <PatternRedRight3
              animate={
                animateAll
                  ? isChooseDeliveryView
                    ? animation.patternRedRight3.animation.clicked
                    : animation.patternRedRight3.animation.default
                  : {}
              }
              variants={animation.patternRedRight3.animation}
              transition={animation.patternRedRight3.transition}
            >
              <Icon src={patternRedIcon3} size={50} alt="pattern-red" />
            </PatternRedRight3>
            <CircleRight>
              <Icon src={circleIcon} size={14} alt="circle" />
            </CircleRight>
            <CloudsHexagonal
              animate={
                animateAll
                  ? isChooseDeliveryView
                    ? animation.cloudsHexagonal.animation.clicked
                    : animation.cloudsHexagonal.animation.default
                  : {}
              }
              variants={animation.cloudsHexagonal.animation}
              transition={animation.cloudsHexagonal.transition}
              isStartingView={isStartingView}
            >
              <Icon src={cloudsIcon} size={43} alt="clouds-hexagonal" />
            </CloudsHexagonal>
            {children}
          </>
        </CentralViewWrapper>
      </Container>
    </>
  )
}

export default Layout
