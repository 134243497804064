import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Cookie from 'js-cookie'

import Container from 'components/shared/container'

import injectGTM from 'utils/injectGTM'
import useView from 'hooks/useView'

const Overlay = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  background: rgba(0, 0, 0, 0.725);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  z-index: 999;

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

const CookiesWrapper = styled.aside`
  z-index: 100;
  width: 100%;
  display: block;
  transition: 0.3s ease;
  opacity: 1;
  max-width: max-content;
  ${({ hidden }) =>
    hidden &&
    css`
      opacity: 0;
      transform: translateY(100px);
    `}

  ${({ theme }) => theme.media.lg.min} {
    transform: translateY(100%);
  }
`

const CookiesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 700px;
  padding: 20px 18px;
  border-radius: 10px;

  ${({ theme }) => theme.media.sm.max} {
    flex-direction: column;
  }
`

const CookiesButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 30px;

  ${({ theme }) => theme.media.sm.max} {
    flex-direction: row-reverse;
    margin: 20px 0 0 0;
  }
`

const CookiesButton = styled.button`
  width: 105px;
  height: 40px;
  margin: 4px 0;
  color: ${({ theme }) => theme.colors.black};
  text-transform: uppercase;
  color: #a51038b1;
  font-size: 1.2rem;
  font-weight: bold;
  border: 2px solid ${({ theme }) => theme.colors.primary100};
  border-radius: 20px;
  cursor: pointer;

  transition: 150ms ease-in-out;

  ${({ theme }) => theme.media.sm.max} {
    width: 125px;
    margin: 0 10px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary100};
    color: ${({ theme }) => theme.colors.white};
  }
`

const CookiesText = styled.p`
  height: fit-content;
  align-self: center;
  font-size: 1.65rem;
  line-height: 1.45;
  margin: 0;
  color: #a8a8a8;

  a {
    position: relative;
    z-index: 2;
    margin-left: 4px;
    color: ${({ theme }) => theme.colors.primary};
  }

  ${({ theme }) => theme.media.sm.max} {
    font-size: 1.25rem;
  }
`

const Cookies = () => {
  const [visible, setVisible] = useState(false)
  const { startAnimateAll } = useView()

  const acceptCookies = () => {
    Cookie.set('dpd-cookies', 'true')
    setVisible(false)
    localStorage.setItem('dpd-cookies', 'true')
    startAnimateAll()
    injectGTM()
  }

  useEffect(() => {
    const value = localStorage.getItem('dpd-cookies')
    if (value) {
      setVisible(false)
      injectGTM()
      startAnimateAll()
    } else {
      setVisible(true)
    }
  }, [])

  return (
    <Overlay isVisible={visible}>
      <CookiesWrapper hidden={!visible}>
        <Container>
          <CookiesContainer>
            <CookiesText>
              Strona korzysta z plików cookies w celu realizacji usług. Możesz
              określić warunki przechowywania lub dostępu do plików cookies w
              Twojej przeglądarce. Sprawdź
              <a
                href="https://www.dpd.com/pl/pl/polityka-prywatnosci/"
                target="_blank"
              >
                politykę prywatności.
              </a>
            </CookiesText>
            <CookiesButtons>
              <CookiesButton onClick={acceptCookies}>Akceptuję</CookiesButton>
              <CookiesButton
                onClick={() => {
                  setVisible(false)
                  startAnimateAll()
                }}
              >
                Nie akceptuję
              </CookiesButton>
            </CookiesButtons>
          </CookiesContainer>
        </Container>
      </CookiesWrapper>
    </Overlay>
  )
}

export default Cookies
