import { useEffect, useState } from 'react'
import getAnimationByView from 'constants/animations'
import useBreakpoint from 'hooks/useBreakpoint'
import useView from 'hooks/useView'

const useAnimation = () => {
  const { xsm } = useBreakpoint()
  const { currentView } = useView()

  const [animation, setAnimation] = useState<any>(
    getAnimationByView(currentView).mobile
  )

  useEffect(() => {
    const animationByView = getAnimationByView(currentView)

    setAnimation(xsm ? animationByView.desktop : animationByView.mobile)
  }, [xsm, currentView])

  return animation
}
export default useAnimation
