/* eslint-disable */
//@ts-ignore

import React, { useEffect } from 'react'
import styled, { css, keyframes } from 'styled-components'

import { motion } from 'framer-motion'

import LazyImage from 'components/shared/lazyImage'
import { Heading, Text } from 'components/shared/typography'
import Icon from 'components/shared/icon'

import arrowRight from 'assets/icons/arrow-right.svg'

import useView from 'hooks/useView'
import getAnimationByView from 'constants/animations'
import useBreakpoint from 'hooks/useBreakpoint'

const animationBorder1 = keyframes`

0% {
  transform: translate(-50%, -50%) rotate(359deg);
}

15% {
  transform: translate(-50%, -50%) rotate(359deg);
}

85% {
  transform: translate(-50%, -50%) rotate(-40deg);
}

100% {
  transform: translate(-50%, -50%) rotate(-40deg);
}


`

const animationBorder2 = keyframes`

0% {
  transform: translate(-50%, -50%) rotate(400deg);
}

15% {
  transform: translate(-50%, -50%) rotate(400deg);
}

85% {
  transform: translate(-50%, -50%) rotate(-25deg);
}

100% {
  transform: translate(-50%, -50%) rotate(-25deg);
}


`

const animationBorder3 = keyframes`

0% {
  transform: translate(-50%, -50%) rotate(440deg);
}

15% {
  transform: translate(-50%, -50%) rotate(440deg);
}

85% {
  transform: translate(-50%, -50%) rotate(-10deg);
}

100% {
  transform: translate(-50%, -50%) rotate(-10deg);
}


`

const animationBorder4 = keyframes`
0% {
  transform: translate(-50%, -50%) rotate(319deg);
}

15% {
  transform: translate(-50%, -50%) rotate(319deg);
}

85% {
  transform: translate(-50%, -50%) rotate(-45deg);
}

100% {
  transform: translate(-50%, -50%) rotate(-45deg);
}


`

const Header = styled.div`
  width: 100%;

  @media (max-height: 680px) {
    ${Heading} {
      font-size: 34px;
      margin-bottom: 10px;
    }
    ${Text} {
      font-size: 16px;
    }
  }
`

const ButtonBorder1 = styled(motion.svg)<{ animate: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-40deg);
  width: 175px;
  height: 175px;
  stroke: #000;
  stroke-width: 2px;
  fill: transparent;

  ${({ animate }) =>
    animate &&
    css`
      animation: ${animationBorder1} 2.15s infinite alternate-reverse
        ease-in-out;
    `}

  circle {
    transform: translate(-50%, -50%);
  }
`

const ButtonBorder2 = styled(motion.svg)<{ animate: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-25deg);
  width: 175px;
  height: 175px;
  stroke: #000;
  stroke-width: 2px;
  fill: transparent;

  ${({ animate }) =>
    animate &&
    css`
      animation: ${animationBorder2} 2.15s infinite alternate-reverse
        ease-in-out;
    `}

  circle {
    transform: translate(-50%, -50%);
  }
`

const ButtonBorder3 = styled(motion.svg)<{ animate: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-10deg);
  width: 175px;
  height: 175px;
  stroke: #000;
  stroke-width: 2px;
  fill: transparent;

  ${({ animate }) =>
    animate &&
    css`
      animation: ${animationBorder3} 2.15s infinite alternate-reverse
        ease-in-out;
    `}

  circle {
    transform: translate(-50%, -50%);
  }
`

const ButtonBorder4 = styled(motion.svg)<{ animate: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 175px;
  height: 175px;
  stroke: #000;
  stroke-width: 2px;
  fill: transparent;

  ${({ animate }) =>
    animate &&
    css`
      animation: ${animationBorder4} 2.15s infinite alternate-reverse
        ease-in-out;
    `}

  circle {
    transform: translate(-50%, -50%);
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const ButtonCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`

const StartButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 175px;
  height: 175px;
  margin-bottom: 10px;
  border-radius: 50%;
  cursor: pointer;
`

const StartButtonInnerCircle = styled(ButtonCircle)`
  width: 135px;
  height: 135px;
  background: ${({ theme }) => theme.colors.primary200};
`

const StartButtonCentralCircle = styled(ButtonCircle)`
  width: 105px;
  height: 105px;
  background: ${({ theme }) => theme.colors.primary100};
`

const PickupStation = styled(motion.div)`
  position: absolute;
  top: 27%;
  left: 7%;
  width: 70px;
  transform: rotate(-4deg);
`

const PickupPoint = styled(motion.div)`
  position: absolute;

  top: 44%;
  left: 29%;
  width: 155px;

  transform: rotate(-8deg);
`

const Courier = styled(motion.div)`
  position: absolute;
  top: 55%;
  right: 5%;
  width: 100px;
`

const StartingViewDesktop: React.FC = () => {
  const {
    currentView,
    pickupPointImg,
    pickupStationImg,
    courierImg,
    animateAll,
    changeView,
    textContent: { mainPageWelcome, mainPageCommunication },
  } = useView()

  const { xsm } = useBreakpoint()

  const drawCircleArc = (circle: any, inputAngle: number, position: number) => {
    // 1. Get angle from input field.
    let angle = inputAngle

    // 2. Radius of SVG circle.
    const radius = 85
    const circumference = 2 * Math.PI * radius

    // 3. First, 1/4 of circumfence of 90 degrees. To start from top of the view,
    //    we must rotate it by 90 degrees. By default circle will start on the right.
    //    Stroke offset effectively rotates the circle.
    // 4. Second, calculate dash array. We need dash array containing only two parts -
    //    visible dash, and invisible dash.
    //    Visible dash should have length of the chosen angle. Full circle is 360 degrees,
    //    and this 360 degrees does also equal the entire circumference. We want just a part of
    //    this entire circle to be visible - (angle / 360 degrees) returns a percentage value
    //    (between 0.0 and 1.0) of how much circumference should be visible.
    //    Hence, we then multiply (angle / 360) times the entire circumference.
    const strokeOffset = (position / 4) * circumference
    const strokeDasharray = (angle / 360) * circumference

    // 5. Set circle radius
    circle.setAttribute('r', radius)
    // 6. Create dash array of two elements (combined they must equal the entire circumference).
    //    First has the length of visible portion. Second, the remaining part.
    circle.setAttribute('stroke-dasharray', [
      strokeDasharray,
      circumference - strokeDasharray,
    ])
    // 7. (Optional) Rotate circle to start from the top.
    circle.setAttribute('stroke-dashoffset', strokeOffset)
  }

  useEffect(() => {
    const circles = document.querySelectorAll('circle')
    circles.forEach((circle, index) => {
      const circleNumber = index + 1
      let angle = 90
      let position = 1

      if (circleNumber === 1) {
        angle = 50
        position = 2
      }

      if (circleNumber === 2) {
        angle = 50
        position = 3
      }

      if (circleNumber === 3) {
        angle = 50
        position = 4
      }

      drawCircleArc(circle, angle, position)
    })
  }, [])

  const animation = getAnimationByView(currentView)

  return (
    <>
      <Header>
        <Heading
          size={36}
          weight={400}
          themecolor="white"
          margin="20px"
          align="center"
          dangerouslySetInnerHTML={{ __html: mainPageWelcome }}
        />
        <Text
          size={17}
          weight={400}
          themecolor="white"
          align="center"
          dangerouslySetInnerHTML={{
            __html: mainPageCommunication,
          }}
        />
      </Header>
      <ButtonWrapper>
        <StartButton
          type="button"
          onClick={() => changeView('chooseDeliveryView')}
        >
          <StartButtonInnerCircle>
            <StartButtonCentralCircle>
              <Icon src={arrowRight} size={30} alt="arrow-right" />
            </StartButtonCentralCircle>
          </StartButtonInnerCircle>
          <ButtonBorder1 viewBox="-175 -175 175 175" animate={animateAll}>
            <circle
              stroke="#ffffff"
              strokeWidth="1px"
              viewBox="-175 -175 175 175"
            />
          </ButtonBorder1>
          <ButtonBorder2 viewBox="-175 -175 175 175" animate={animateAll}>
            <circle
              stroke="#ffffff"
              strokeWidth="1px"
              viewBox="-175 -175 175 175"
            />
          </ButtonBorder2>
          <ButtonBorder3 viewBox="-175 -175 175 175" animate={animateAll}>
            <circle
              stroke="#ffffff"
              strokeWidth="1px"
              viewBox="-175 -175 175 175"
            />
          </ButtonBorder3>
          <ButtonBorder4 viewBox="-175 -175 175 175" animate={animateAll}>
            <circle
              stroke="#ffffff"
              strokeWidth="1px"
              viewBox="-175 -175 175 175"
            />
          </ButtonBorder4>
        </StartButton>
        <Text
          size={20}
          weight={400}
          themecolor="white"
          dangerouslySetInnerHTML={{
            __html: 'Zaczynamy!',
          }}
          align="center"
        />
      </ButtonWrapper>

      {/* Layout Icons */}
      <PickupStation
        animate={
          animateAll
            ? xsm
              ? animation.desktop.pickupStationIcon.animation
              : animation.mobile.pickupStationIcon.animation
            : {}
        }
        transition={
          xsm
            ? animation.desktop.pickupStationIcon.transition
            : animation.mobile.pickupStationIcon.transition
        }
      >
        <LazyImage src={pickupStationImg} alt="pickup-station" />
      </PickupStation>
      <PickupPoint
        animate={
          animateAll
            ? xsm
              ? animation.desktop.pickupPointIcon.animation
              : animation.mobile.pickupPointIcon.animation
            : {}
        }
        transition={
          xsm
            ? animation.desktop.pickupPointIcon.transition
            : animation.mobile.pickupPointIcon.transition
        }
      >
        <LazyImage src={pickupPointImg} alt="pickup-point" />
      </PickupPoint>
      <Courier
        animate={
          animateAll
            ? xsm
              ? animation.desktop.courierIcon.animation
              : animation.mobile.courierIcon.animation
            : {}
        }
        transition={
          xsm
            ? animation.desktop.courierIcon.transition
            : animation.mobile.courierIcon.transition
        }
      >
        <LazyImage src={courierImg} alt="truck" />
      </Courier>
    </>
  )
}

export default StartingViewDesktop
