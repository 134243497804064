import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion, Variants } from 'framer-motion'

import LazyImage from 'components/shared/lazyImage'
import { ContinueButton } from 'components/shared/button'
import { Text } from 'components/shared/typography'

import type { Delivery } from 'types/delivery'
import useView from 'hooks/useView'
import useDeliveryTypes from 'hooks/useDeliveryTypes'

import { getDeliveryProps } from 'constants/deliveryTypes'
import { getDeliveryAnimation } from 'constants/animations/deliveryView'

import { moveFirstItemToLast, moveLastItemToFirst } from 'utils/moveArrayItem'

type Props = {
  onChooseDelivery: () => void
  currentDelivery: Delivery
  setCurrentDelivery: (delivery: Delivery) => void
  clicked: boolean
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

const LabelTopWrapper = styled.div`
  height: 56px;
  min-height: 56px;
  margin-bottom: 7px;
`

const Buttons = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 230px;
  margin-top: 20px;

  @media (max-height: 680px) {
    margin-top: 15px;
  }

  @media (max-height: 640px) {
    margin-top: 10px;
  }
`

const Button = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  z-index: 1;

  div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  &:last-of-type > div {
    transform: rotate(180deg) translateX(-2px);
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background: #c11038;
    background: -webkit-radial-gradient(bottom left, #c11038, #dd0b2f);
    background: -moz-radial-gradient(bottom left, #c11038, #dd0b2f);
    background: radial-gradient(to top right, #c11038, #dd0b2f);
    opacity: 0;
    border-radius: inherit;
    transition: 150ms ease-in;
  }

  &:hover {
    background: transparent;
    &:before {
      opacity: 1;
    }

    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  @media (max-height: 680px) {
    width: 55px;
    height: 55px;
  }

  @media (max-height: 640px) {
    width: 50px;
    height: 50px;
  }
`

const WrapperBottom = styled(motion.div)<{
  isPickupStation: boolean
  isPickupPoint: boolean
  isCourier: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  position: absolute;
  width: 375px;
  bottom: 55px;
  padding: 0 30px;
  /* visibility: hidden; */

  ${({ isPickupPoint }) =>
    isPickupPoint &&
    css`
      margin-top: -55px;
    `}

  ${({ isCourier }) =>
    isCourier &&
    css`
      margin-top: -55px;
    `}

    @media (max-height: 680px) {
    ${Text} {
      font-size: 15px;
    }
  }
`

const fadeOutIcon = {
  clicked: {
    opacity: 0,
  },
  initial: {
    opacity: 0.5,
  },
}

const fadeOutDisplayNone = {
  clicked: {
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
  },
  initial: {
    opacity: 1,
  },
}

const fadeOutDisplayNone2 = {
  clicked: {
    opacity: 1,
  },
  initial: {
    opacity: 1,
  },
}

const fadeIn = {
  clicked: { opacity: 1 },
  initial: {
    opacity: 0,
  },
}

const fadeInAbsolute: Variants = {
  clicked: {
    opacity: 1,
  },
  initial: {
    opacity: 0,
  },
}

const SwiperWrapper = styled.div`
  width: 1000px;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 20px;
  margin-bottom: 30px;

  @media (max-height: 680px) {
    margin-top: 5px;
    margin-bottom: 20px;
  }
`

const SlideWrapper = styled(motion.div)<{
  active?: boolean
  lastItem?: boolean
  isPickupPoint?: boolean
  isCourier?: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 240px;
  opacity: 0.5;
  transform: scale(0.8);
  z-index: 1;

  div,
  img {
    max-height: 100%;
  }

  ${({ active }) =>
    active &&
    css`
      margin: 0 20px;
      opacity: 1;
      transform: scale(1);
    `}

  ${({ lastItem }) =>
    lastItem &&
    css`
      margin: 0 auto;
    `}

    ${({ isPickupPoint }) =>
    isPickupPoint &&
    css`
      & > .gatsby-image-wrapper {
        max-width: 75%;
      }
    `}

    ${({ isCourier }) =>
    isCourier &&
    css`
      & > .gatsby-image-wrapper {
        max-width: 60%;
      }
    `}

    @media (max-height: 680px) {
    height: 200px;

    & > .gatsby-image-wrapper {
      max-height: 160px;
    }
  }

  @media (max-height: 640px) {
    height: 190px;

    & > .gatsby-image-wrapper {
      max-height: 155px;
    }
  }
`

const ChooseSlider: React.FC<Props> = ({
  onChooseDelivery,
  currentDelivery,
  setCurrentDelivery,
  clicked,
}) => {
  const { available, availableSlides } = useDeliveryTypes()
  const { changeView } = useView()
  const animation = getDeliveryAnimation(currentDelivery)
  const animationSmallDevice = {
    deliveryLabel: {
      clicked: {
        y: -285,
      },
      initial: {},
    },
  }

  const [deliveries, setDeliveries] = useState(availableSlides)

  const windowInnerHeight = window.innerHeight

  const currentIndex = available.findIndex(
    (delivery) => currentDelivery === delivery
  )

  const goPrev = () => {
    if (available.length) {
      setCurrentDelivery(
        available[
          currentIndex - 1 < 0 ? available.length - 1 : currentIndex - 1
        ]
      )
    }

    const updatedArr = moveFirstItemToLast([...deliveries])
    setDeliveries(updatedArr)
  }

  const goNext = () => {
    if (available.length) {
      setCurrentDelivery(available[(currentIndex + 1) % available.length])
    }

    const updatedArr = moveLastItemToFirst([...deliveries])
    setDeliveries(updatedArr)
  }

  return (
    <Wrapper>
      <LabelTopWrapper>
        <motion.div
          variants={fadeOutDisplayNone}
          animate={clicked ? 'clicked' : 'initial'}
          transition={{
            duration: 0.5,
          }}
        >
          <Text
            size={20}
            weight={400}
            themecolor="white"
            align="center"
            margin="0"
            dangerouslySetInnerHTML={{
              __html: 'Wybierz sposób <br/> doręczenia przesyłki',
            }}
          />
        </motion.div>
        {/* <motion.div
          variants={fadeIn}
          animate={clicked ? 'clicked' : 'initial'}
          initial={{
            opacity: 0,
          }}
          transition={{
            duration: 0.5,
            delay: animation.mainIcon.duration - 0.5,
          }}
        >
          <Text
            size={20}
            weight={400}
            themecolor="white"
            align="center"
            margin="0"
            dangerouslySetInnerHTML={{
              __html: `${getDeliveryProps(currentDelivery).labelTop}`,
            }}
          />
        </motion.div> */}
      </LabelTopWrapper>
      <SwiperWrapper>
        {deliveries.map(({ src, id }, index) => {
          const isActive = deliveries.length === 1 || index === 1

          return (
            <SlideWrapper
              key={index}
              active={isActive}
              animate={clicked ? 'clicked' : 'initial'}
              variants={isActive ? animation.mainIcon.animation : fadeOutIcon}
              transition={{
                duration: isActive ? animation.mainIcon.duration : 1,
                delay: isActive ? animation.mainIcon.delay : 0,
                ease: 'easeInOut',
              }}
              lastItem={deliveries.length === 1}
              isPickupPoint={id === 'pickupPoint'}
              isCourier={id === 'courier'}
            >
              <LazyImage src={src} alt={id} objectFit="contain" />
              {/* <Rectangle
                isPickupPoint={currentDelivery === 'pickupPoint'}
                isCourier={currentDelivery === 'courier'}
              /> */}
            </SlideWrapper>
          )
        })}
        {deliveries.length === 2 && (
          <SlideWrapper
            animate={clicked ? 'clicked' : 'initial'}
            variants={fadeOutIcon}
            transition={{
              duration: 1,
              delay: 0,
              ease: 'easeInOut',
            }}
            isPickupPoint={deliveries[0].id === 'pickupPoint'}
            isCourier={deliveries[0].id === 'courier'}
          >
            <LazyImage
              src={deliveries[0].src}
              alt={deliveries[0].id}
              objectFit="contain"
            />
          </SlideWrapper>
        )}
      </SwiperWrapper>

      <motion.div
        animate={clicked ? 'clicked' : 'initial'}
        variants={
          windowInnerHeight < 681
            ? animationSmallDevice.deliveryLabel
            : animation.deliveryLabel.animation
        }
        transition={{
          duration: animation.deliveryLabel.duration,
          delay: animation.deliveryLabel.delay,
        }}
      >
        <motion.div
          variants={fadeOutDisplayNone2}
          animate={clicked ? 'clicked' : 'initial'}
          transition={{
            duration: 0.5,
            delay: animation.mainIcon.duration - 0.75,
          }}
        >
          <Text
            size={20}
            weight={400}
            themecolor="white"
            align="center"
            margin="30px"
            dangerouslySetInnerHTML={{
              __html: getDeliveryProps(currentDelivery).label,
            }}
          />
        </motion.div>
      </motion.div>
      <motion.div
        variants={fadeOutDisplayNone}
        animate={clicked ? 'clicked' : 'initial'}
        transition={{
          duration: 0.5,
        }}
      >
        <ContinueButton
          animate={{
            opacity: 1,
          }}
          transition={{
            delay: 0,
            duration: 0,
          }}
          label="Wybierz"
          onClick={onChooseDelivery}
        />
      </motion.div>
      {available.length > 1 && (
        <Buttons
          variants={fadeOutDisplayNone}
          animate={clicked ? 'clicked' : 'initial'}
          transition={{
            duration: 0.5,
          }}
        >
          <Button type="button" onClick={goPrev}>
            <div>
              <svg
                width="48px"
                height="48px"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.0607 8.18934C32.6464 8.77513 32.6464 9.72487 32.0607 10.3107L18.6213 23.75L32.0607 37.1893C32.6464 37.7751 32.6464 38.7249 32.0607 39.3107C31.4749 39.8964 30.5251 39.8964 29.9393 39.3107L15.4393 24.8107C14.8536 24.2249 14.8536 23.2751 15.4393 22.6893L29.9393 8.18934C30.5251 7.60355 31.4749 7.60355 32.0607 8.18934Z"
                  fill="#A51038"
                />
              </svg>
            </div>
          </Button>
          <Button type="button" onClick={goNext}>
            <div>
              <svg
                width="48px"
                height="48px"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.0607 8.18934C32.6464 8.77513 32.6464 9.72487 32.0607 10.3107L18.6213 23.75L32.0607 37.1893C32.6464 37.7751 32.6464 38.7249 32.0607 39.3107C31.4749 39.8964 30.5251 39.8964 29.9393 39.3107L15.4393 24.8107C14.8536 24.2249 14.8536 23.2751 15.4393 22.6893L29.9393 8.18934C30.5251 7.60355 31.4749 7.60355 32.0607 8.18934Z"
                  fill="#A51038"
                />
              </svg>
            </div>
          </Button>
        </Buttons>
      )}
      <WrapperBottom
        variants={fadeInAbsolute}
        animate={clicked ? 'clicked' : 'initial'}
        transition={{
          delay: 0.6,
          duration: 0.5,
        }}
        isPickupStation={currentDelivery === 'pickupStation'}
        isPickupPoint={currentDelivery === 'pickupPoint'}
        isCourier={currentDelivery === 'courier'}
      >
        <Text
          size={16}
          weight={400}
          themecolor="white"
          align="center"
          margin={currentDelivery === 'courier' ? '55px' : '40px'}
          dangerouslySetInnerHTML={{
            __html: getDeliveryProps(currentDelivery).description,
          }}
        />

        <ContinueButton
          animate={{
            opacity: 1,
          }}
          transition={{
            delay: 0,
            duration: 0.5,
          }}
          label="Przejdź dalej"
          withIcon
          onClick={() => changeView('deliveryBenefitsView')}
        />
      </WrapperBottom>
    </Wrapper>
  )
}

export default ChooseSlider
