import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout/layout'
import SEO from 'components/shared/SEO'

import Views from 'components/layout/Views/Views'

const IndexPage: React.FC<PageProps> = ({ data }) => {
  return (
    <Layout>
      <SEO
      // seo={{
      //   title: data.wpPage.seo.title || '',
      //   metaDesc: data.wpPage.seo.metaDesc || '',
      //   metaKeywords: data.wpPage.seo.metaKeywords || '',
      //   metaRobotsNofollow: data.wpPage.seo.metaRobotsNofollow || '',
      //   metaRobotsNoindex: data.wpPage.seo.metaRobotsNoindex || '',
      //   opengraphAuthor: data.wpPage.seo.opengraphAuthor || '',
      //   opengraphDescription: data.wpPage.seo.opengraphDescription || '',
      // }}
      />
      <Views />
    </Layout>
  )
}

export default IndexPage

// export const query = graphql`
//   # query IndexPage {
//   #   wpPage {
//   #     seo {
//   #       title
//   #       opengraphDescription
//   #       opengraphAuthor
//   #       metaRobotsNoindex
//   #       metaRobotsNofollow
//   #       metaKeywords
//   #       metaDesc
//   #     }
//   #   }
//   # }
// `
