import React, { useState } from 'react'

import ChoiceSliderDesktop from 'components/layout/Views/ChooseDeliveryView/ChoiceSliderDesktop'

import useDeliveryTypes from 'hooks/useDeliveryTypes'
import useView from 'hooks/useView'

import type { Delivery } from 'types/delivery'

const ChooseDeliveryViewDesktop: React.FC = () => {
  const { selectDeliveryType, available } = useDeliveryTypes()
  const { changeView } = useView()
  const [currentDelivery, setCurrentDelivery] = useState<Delivery>(available[0])
  const [deliveryClicked, setIsDeliveryClicked] = useState(false)

  const handleChooseDelivery = () => {
    setIsDeliveryClicked(true)
    selectDeliveryType(currentDelivery)
    changeView('deliveryView')
  }

  return (
    <ChoiceSliderDesktop
      currentDelivery={currentDelivery}
      setCurrentDelivery={setCurrentDelivery}
      onChooseDelivery={handleChooseDelivery}
      clicked={deliveryClicked}
    />
  )
}

export default ChooseDeliveryViewDesktop
