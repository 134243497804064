import React from 'react'

import styled, { css } from 'styled-components'
import { darken, readableColor } from 'polished'
import { Colors } from 'types/theme'
import { colors } from 'styles/theme'
import { pxToRem } from 'styles/mixins'
import { motion } from 'framer-motion'

import Icon from 'components/shared/icon'

import loadingIcon from 'assets/icons/loading.svg'
import arrowRightIcon from 'assets/icons/arrow-right-white.svg'

type ButtonProps = {
  [key in keyof Colors]?: boolean
} & {
  outline?: boolean
  loading?: boolean
  square?: boolean
  marginRight?: boolean
  marginLeft?: boolean
}

const Button = styled.button<ButtonProps>`
  display: inline-block;
  position: relative;

  padding: ${({ square }) =>
    square ? `${pxToRem(6)}em` : `${pxToRem(6)}em ${pxToRem(12)}em`};

  border: 2px solid transparent;

  font-size: ${pxToRem(16)}rem;
  font-weight: 400;
  text-decoration: none;

  cursor: pointer;
  transition: color 100ms ease, background-color 100ms ease,
    border-color 100ms ease;

  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: ${pxToRem(6)}em;
    `}

  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${pxToRem(6)}em;
    `}

  ${(props) => {
    const keys = Object.keys(props)
    const colorProps = Object.keys(colors)

    for (const key of keys) {
      if (
        colorProps.includes(key) &&
        Boolean(props[key as keyof typeof props])
      ) {
        return css`
          background: ${({ theme }) => theme.colors[key as keyof Colors]};
          border-color: ${({ theme }) => theme.colors[key as keyof Colors]};
          color: ${({ theme }) =>
            readableColor(
              String(theme.colors[key as keyof Colors]),
              theme.colors.black,
              theme.colors.white
            )};

          &:hover {
            background: ${({ theme }) =>
              darken(0.05, String(theme.colors[key as keyof Colors]))};
            border-color: ${({ theme }) =>
              darken(0.05, String(theme.colors[key as keyof Colors]))};
            color: ${({ theme }) =>
              readableColor(
                darken(0.05, String(theme.colors[key as keyof Colors])),
                theme.colors.black,
                theme.colors.white
              )};
          }
        `
      }
    }

    return null
  }}

    ${({ outline }) =>
    outline &&
    css`
      background: transparent;
      color: ${({ theme }) => theme.colors.black};
    `}

    ${({ loading }) =>
    loading &&
    css`
      opacity: 0.7;
      cursor: wait;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: inherit;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background-image: url(${loadingIcon});
        background-size: auto 75%;
        background-position: center;
        background-repeat: no-repeat;
      }
    `}

    &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
`

Button.defaultProps = {
  primary: true,
}

export default Button

const Arrow = styled.div<{ inverted?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 8%;
  z-index: 1;
  transform: translateY(-50%);
  transition: 150ms ease-in-out;

  ${({ inverted }) =>
    inverted &&
    css`
      transform: translateY(-50%) rotate(180deg);
    `}
`

const StyledContinueButton = styled(motion.button)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 235px;
  min-width: 240px;
  height: 55px;
  min-height: 55px;
  margin-top: auto;
  border-radius: 12px;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.colors.white};
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  transition: 150ms ease-in-out;

  @media (hover: hover) {
    &:hover {
      background: #c11038;
      background: -webkit-radial-gradient(bottom left, #c11038, #dd0b2f);
      background: -moz-radial-gradient(bottom left, #c11038, #dd0b2f);
      background: radial-gradient(to top right, #c11038, #dd0b2f);
      border-color: #c11038;

      ${Arrow} {
        right: 6%;
      }
    }
  }

  @media (max-height: 680px) {
    height: 50px;
    min-height: 50px;
    font-size: 15px;
  }
`

type ContinueButtonProps = {
  label: string
  animate?: any
  initial?: any
  transition?: {
    delay: number
    duration: number
  }
  withIcon?: boolean
  arrowLeft?: boolean
  onClick?: () => void
}

export const ContinueButton: React.FC<ContinueButtonProps> = ({
  label,
  animate,
  initial,
  transition,
  withIcon,
  arrowLeft,
  onClick,
}) => (
  <StyledContinueButton
    animate={animate}
    initial={initial}
    transition={transition}
    onClick={onClick}
  >
    {label}
    {withIcon && (
      <Arrow inverted={arrowLeft}>
        <Icon src={arrowRightIcon} size={20} alt="arrow" />
      </Arrow>
    )}
  </StyledContinueButton>
)
