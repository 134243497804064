import React from 'react'
import styled from 'styled-components'

import { Heading, Text } from 'components/shared/typography'

import Icon from 'components/shared/icon'

import { ContinueButton } from 'components/shared/button'

import iosDownloadIcon from 'assets/icons/ios-download.svg'
import googlePlayDownloadIcon from 'assets/icons/google-play-download.svg'

import useView from 'hooks/useView'
import useDeliveryTypes from 'hooks/useDeliveryTypes'

const StyledHeading = styled(Heading)`
  @media (max-height: 680px) {
    font-size: 20px;
  }
`

const StyledText = styled(Text)`
  @media (max-height: 680px) {
    font-size: 16px;
  }
`

const DownloadButtons = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 240px;
    position: relative;
    div,
    img {
      width: 100%;
    }
  }

  button > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const ButtonsBottom = styled.div`
  margin-top: 15px;
  button:first-of-type {
    margin-bottom: 15px;
  }
`

const FinalViewDesktop: React.FC = () => {
  const {
    changeView,
    textContent: { appTitle, appDescription, appSend },
  } = useView()
  const { resetSelectedDeliveryTypes } = useDeliveryTypes()

  return (
    <>
      <StyledHeading
        size={22}
        weight={400}
        themecolor="white"
        margin="60px"
        align="center"
        dangerouslySetInnerHTML={{
          __html: appTitle,
        }}
      />
      <StyledText
        size={18}
        weight={400}
        themecolor="white"
        align="center"
        margin="40px"
        dangerouslySetInnerHTML={{
          __html: appDescription,
        }}
      />
      <DownloadButtons>
        <button type="button">
          <Icon src={googlePlayDownloadIcon} auto alt="google-play-download" />
          <a
            href="https://play.google.com/store/apps/details?id=pl.com.dpd.mobile.app.release&hl=pl&gl=US"
            target="_blank"
          />
        </button>
        <button type="button">
          <Icon src={iosDownloadIcon} auto alt="ios-download" />
          <a
            href="https://apps.apple.com/pl/app/dpd-mobile/id1593210812?l=pl"
            target="_blank"
          />
        </button>
      </DownloadButtons>
      <ButtonsBottom>
        <a href={appSend} target="_blank">
          <ContinueButton
            initial={{
              opacity: 1,
            }}
            label="Nadaj przesyłkę"
            withIcon
          />
        </a>
        <ContinueButton
          initial={{
            opacity: 1,
          }}
          label="Wróć do początku"
          withIcon
          arrowLeft
          onClick={() => {
            resetSelectedDeliveryTypes()
            changeView('chooseDeliveryView')
          }}
        />
      </ButtonsBottom>
    </>
  )
}

export default FinalViewDesktop
