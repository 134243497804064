import { ContextType, useContext } from 'react'

import { DeliveryContext } from 'context/delivery'

const useDeliveryTypes = (): NonNullable<
  ContextType<typeof DeliveryContext>
> => {
  const context = useContext(DeliveryContext)

  if (!context) {
    throw new Error('useDeliveryTypes must be used inside DeliveryProvider.')
  }

  return context
}

export default useDeliveryTypes
