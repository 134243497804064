/* eslint-disable no-restricted-syntax */
import type { View, ViewAnimation } from 'types/view'

import STARTING_VIEW_ANIMATION from './startingView'
import CHOOSE_DELIVERY_VIEW_ANIMATION from './chooseDeliveryView'
import DELIVERY_VIEW_ANIMATION from './deliveryView'
import DELIVERY_BENEFITS_VIEW_ANIMATION from './deliveryBenefitsView'
import FINAL_VIEW_ANIMATION from './finalView'
import BASE_ANIMATION from './baseView'

export {
  STARTING_VIEW_ANIMATION,
  CHOOSE_DELIVERY_VIEW_ANIMATION,
  DELIVERY_BENEFITS_VIEW_ANIMATION,
  DELIVERY_VIEW_ANIMATION,
  FINAL_VIEW_ANIMATION,
}

const getAnimationByView = (view: View) => {
  switch (view) {
    case 'startingView':
      return STARTING_VIEW_ANIMATION
    case 'chooseDeliveryView':
      return CHOOSE_DELIVERY_VIEW_ANIMATION
    case 'deliveryView':
      return DELIVERY_VIEW_ANIMATION
    case 'deliveryBenefitsView':
      return DELIVERY_BENEFITS_VIEW_ANIMATION
    case 'finalView':
      return FINAL_VIEW_ANIMATION
    default:
      return BASE_ANIMATION as ViewAnimation
  }
}

export default getAnimationByView
