import * as React from 'react'
import { Helmet } from 'react-helmet'
// import { useStaticQuery, graphql } from 'gatsby'

const Seo: React.FC = () => {
  // const query = useStaticQuery(
  //   graphql`
  //     query SEO {
  //       wpPage {
  //         seo {
  //           title
  //           opengraphDescription
  //           opengraphAuthor
  //           metaRobotsNoindex
  //           metaRobotsNofollow
  //           metaKeywords
  //           metaDesc
  //         }
  //       }
  //     }
  //   `
  // )

  // const SEO = query.wpPage.seo

  const SEO = {
    title: 'W DPD to Ty decydujesz, jak doręczymy Twoją paczkę!',
    opengraphTitle: 'W DPD to Ty decydujesz, jak doręczymy Twoją paczkę!',
    twitterTitle: 'W DPD to Ty decydujesz, jak doręczymy Twoją paczkę!',
    opengraphDescription:
      'Wybierz formę doręczenia przesyłki: Kurier, Automat paczkowy, Punkt DPD Pickup - jak Ci wygodnie. Sam zdecyduj jak doręczymy Twoją przesyłkę!',
    twitterDescription:
      'Wybierz formę doręczenia przesyłki: Kurier, Automat paczkowy, Punkt DPD Pickup - jak Ci wygodnie. Sam zdecyduj jak doręczymy Twoją przesyłkę!',
    metaDesc:
      'Wybierz formę doręczenia przesyłki: Kurier, Automat paczkowy, Punkt DPD Pickup - jak Ci wygodnie. Sam zdecyduj jak doręczymy Twoją przesyłkę!',
  }

  const meta = [
    // {
    //   property: `robots`,
    //   content: `index, follow`,
    // },
    {
      name: `description`,
      content:
        'Wybierz formę doręczenia przesyłki: Kurier, Automat paczkowy, Punkt DPD Pickup - jak Ci wygodnie. Sam zdecyduj jak doręczymy Twoją przesyłkę!',
    },
    {
      property: `og:title`,
      content: SEO.opengraphTitle || SEO.title || '',
    },
    {
      property: `og:description`,
      content: SEO.opengraphDescription || SEO.metaDesc || '',
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: `inDigital`,
    },
    {
      name: 'author',
      content: 'inDigital',
    },
    {
      name: `twitter:title`,
      content: SEO.twitterTitle || SEO.title || '',
    },
    {
      name: `twitter:description`,
      content: SEO.twitterDescription || SEO.metaDesc || '',
    },
  ]

  return <Helmet title={SEO.title || ''} titleTemplate="%s" meta={meta} />
}

export default Seo
