import React, { useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'

import Icon from 'components/shared/icon'
import { Text } from 'components/shared/typography'
import { ContinueButton } from 'components/shared/button'

import useDeliveryTypes from 'hooks/useDeliveryTypes'
import useView from 'hooks/useView'

const BenefitButton = styled.button<{
  number: number
  active: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 3px;
  width: 52px;
  height: 52px;
  top: 50%;
  left: 50%;
  /* Created with https://www.css-gradient.com */
  border-radius: 50%;
  background: #fbfbfb;
  background: -webkit-radial-gradient(center, #fbfbfb, #dfdfdf);
  background: -moz-radial-gradient(center, #fbfbfb, #dfdfdf);
  background: radial-gradient(ellipse at center, #fbfbfb, #dfdfdf);
  box-sizing: content-box;
  z-index: 2;
  cursor: pointer;
  transition: 100ms ease-in-out;
  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: transparent;
    border: 18px solid #8c1132;
    border-radius: inherit;
    opacity: 0;
    z-index: -1;
    transition: 100ms ease-in-out;
  }
  ${({ number }) =>
    number === 1 &&
    css`
      transform: translate(-270%, -290%);
    `}
  ${({ number }) =>
    number === 2 &&
    css`
      transform: translate(-50%, -213%);
    `}
    ${({ number }) =>
    number === 3 &&
    css`
      transform: translate(150%, -42%);
    `}
    ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.colors.primary100};
      img {
        transition: 150ms;
        filter: brightness(0) invert(1);
      }
      &:after {
        opacity: 1;
      }
    `}
`

const pulseAnimation = keyframes`
	0% {
    top: 50%;
    left: 50%;
		transform: translate(-50%, -50%) scale(0.95);
		box-shadow: 0 0 0 0 rgba(220, 220, 220, 0.7);
	}

	70% {
    top: 50%;
    left: 50%;
		transform: translate(-50%, -50%) scale(1);
		box-shadow: 0 0 0 12px rgba(220, 220, 220, 0);
	}

	100% {
    top: 50%;
    left: 50%;
		transform: translate(-50%, -50%) scale(0.95);
		box-shadow: 0 0 0 0 rgba(220, 220, 220, 0);
	}

`

const Pulse = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background: transparent;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(220, 220, 220, 0);
  z-index: 0;
  animation: ${pulseAnimation} 2s infinite;
`

const BenefitLabelTop = styled(Text)`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  @media (max-height: 680px) {
    font-size: 18px;
  }
`

const BenefitLabel = styled(Text)`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -15%);
  z-index: 1;

  @media (max-height: 680px) {
    font-size: 22px;
  }
`

const Footer = styled.div`
  margin-top: auto;

  @media (max-height: 640px) {
    margin-bottom: 35px;
  }
`

const DeliveryBenefitsViewDesktop: React.FC = () => {
  const [activeBenefitIndex, setActiveBenefitIndex] = useState(1)
  const { available, lastSelected } = useDeliveryTypes()
  const { changeView } = useView()

  const handleContinue = () => {
    changeView(available.length > 0 ? 'chooseDeliveryView' : 'finalView')
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const newActiveIndex =
        activeBenefitIndex === 2 ? 0 : activeBenefitIndex + 1
      setActiveBenefitIndex(newActiveIndex)
    }, 2750)
    return () => clearInterval(interval)
  }, [activeBenefitIndex])

  return (
    <>
      {lastSelected?.benefits.map((item, index) => (
        <BenefitButton
          type="button"
          key={item.text}
          number={index + 1}
          active={index === activeBenefitIndex}
          onClick={() => setActiveBenefitIndex(index)}
        >
          <Icon src={item.icon} size={50} alt={item.text} />
          {index !== activeBenefitIndex && <Pulse />}
        </BenefitButton>
      ))}
      <BenefitLabelTop
        size={20}
        themecolor="white"
        align="center"
        dangerouslySetInnerHTML={{
          __html: lastSelected?.benefitLabelTop!,
        }}
      />
      <BenefitLabel
        size={24}
        themecolor="white"
        align="center"
        dangerouslySetInnerHTML={{
          __html: lastSelected?.benefits[activeBenefitIndex].text!,
        }}
      />
      <Footer>
        <Text
          size={16}
          themecolor="white"
          align="center"
          margin="20px"
          dangerouslySetInnerHTML={{
            __html: 'Pobierz DPD Mobile <br/> i decyduj!',
          }}
        />
        <ContinueButton
          initial={{
            opacity: 1,
          }}
          label="Przejdź dalej"
          withIcon
          onClick={handleContinue}
        />
      </Footer>
    </>
  )
}

export default DeliveryBenefitsViewDesktop
