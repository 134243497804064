import React, { useEffect } from 'react'
import styled, { css, CSSProperties } from 'styled-components'

import background from 'assets/images/background.svg'
import backgroundRounded from 'assets/images/background-rounded.svg'
import dpdLogo from 'assets/icons/dpd-logo.svg'

import useBreakpoint from 'hooks/useBreakpoint'
import Icon from 'components/shared/icon'

import useView from 'hooks/useView'

type Props = {
  children: JSX.Element | JSX.Element[]
  padding?: string
  justifyContent?: CSSProperties['justifyContent']
}

const PageViewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100vw;
`

const Wrapper = styled.main<{
  roundedBackground: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 375px;
  max-height: 720px;
  background-image: url('${background}');
  background-repeat: no-repeat;

  ${({ theme }) => theme.media.xsm.max} {
    overflow: hidden;
    max-width: initial;
    max-height: initial;
    background-size: cover;
  }

  ${({ roundedBackground }) =>
    roundedBackground &&
    css`
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;
      background-image: url('${backgroundRounded}');
    `}
`

const Container = styled.div<{
  padding?: string
  justifyContent?: CSSProperties['justifyContent']
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 375px;
  height: 100%;
  box-sizing: border-box;
  padding: 60px 24px 30px;

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}

  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}

    @media( max-height: 680px) {
    padding-top: 60px;
    padding-bottom: 25px;
    padding-left: 25px;
    padding-right: 25px;
  }

  @media (max-height: 640px) {
    padding-top: 50px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
`

const LogoWrapper = styled.div`
  position: absolute;
  top: 4.5%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const CentralViewWrapper: React.FC<Props> = ({
  children,
  padding,
  justifyContent,
}) => {
  const { xsm } = useBreakpoint()
  const { currentView } = useView()

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('—-app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight()
  }, [currentView])

  return (
    <PageViewWrapper>
      <Wrapper roundedBackground={xsm}>
        <Container padding={padding} justifyContent={justifyContent}>
          {xsm && (
            <LogoWrapper>
              <Icon src={dpdLogo} size={25} alt="dpd-logo" />
            </LogoWrapper>
          )}
          {children}
        </Container>
      </Wrapper>
    </PageViewWrapper>
  )
}

export default CentralViewWrapper
