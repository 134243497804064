import { Delivery } from 'types/delivery'
import { ViewAnimation } from 'types/view'
import BASE_ANIMATION from './baseView'

const CHOOSE_DELIVERY_VIEW_ANIMATION: ViewAnimation = {
  mobile: {
    ...BASE_ANIMATION.mobile,
    cloudLeft1: {
      animation: {
        default: { x: [0, 600], y: [0, 350] },
        clicked: {},
      },
      transition: {
        duration: 1,
        delay: 0,
        ease: 'easeInOut',
      },
    },
    cloudLeft2: {
      animation: {
        default: {
          x: [0, 600],
          y: [0, 350],
        },
        clicked: {},
      },
      transition: {
        duration: 1,
        delay: 0,
        ease: 'easeInOut',
      },
    },
    cloudRight1: {
      animation: {
        default: {
          y: [0, 350],
          x: [0, -600],
        },
        clicked: {},
      },
      transition: {
        duration: 1,
        delay: 0,
        ease: 'easeInOut',
      },
    },
  },

  desktop: {
    ...BASE_ANIMATION.desktop,
    whiteLinePattern: {
      animation: {
        default: {
          scale: [1, 1.15],
        },
        clicked: {},
      },
      transition: {
        duration: 0.75,
        delay: 0,
        ease: 'anticipate',
      },
    },
    cloudLeft1: {
      animation: {
        default: { x: [0, 90], y: [0, 10] },
        clicked: {},
      },
      transition: {
        duration: 0.75,
        delay: 0,
        ease: 'anticipate',
      },
    },
    cloudLeft2: {
      animation: {
        default: {
          x: [0, 90],
          y: [0, 10],
        },
        clicked: {},
      },
      transition: {
        duration: 0.75,
        delay: 0,
        ease: 'anticipate',
      },
    },
    cloudLeft3: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {
        duration: 0.75,
        delay: 0,
        ease: 'anticipate',
      },
    },
    patternRedLeft1: {
      animation: {
        default: {
          scale: [1, 1.25],
          x: [0, -58],
          y: [0, -45],
        },
        clicked: {},
      },
      transition: {
        duration: 0.75,
        delay: 0,
        ease: 'anticipate',
      },
    },
    cloudLeft4: {
      animation: {
        default: {
          x: [0, 20],
          y: [0, 10],
        },
        clicked: {},
      },
      transition: {
        duration: 0.75,
        delay: 0,
        ease: 'anticipate',
      },
    },
    patternRedLeft2: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {
        duration: 0.75,
        delay: 0,
        ease: 'anticipate',
      },
    },
    patternRedLeft5: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {
        duration: 0.75,
        delay: 0,
        ease: 'anticipate',
      },
    },
    circleLeft: {
      animation: {
        default: { x: [0, -50] },
        clicked: {},
      },
      transition: {
        duration: 0.75,
        delay: 0,
        ease: 'anticipate',
      },
    },
    cloudRight2: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    circleRedLeft: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {
        duration: 0.75,
        delay: 0,
        ease: 'anticipate',
      },
    },
    circleRedRight: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {
        duration: 0.75,
        delay: 0,
        ease: 'anticipate',
      },
    },
    patternRedRight1: {
      animation: {
        default: {
          x: [0, 75],
          y: [0, -30],
          rotate: [110, 110],
        },
        clicked: {},
      },
      transition: {
        duration: 0.75,
        delay: 0,
        ease: 'anticipate',
      },
    },
    patternRedRight2: {
      animation: {
        default: {
          opacity: [1, 0],
        },
        clicked: {},
      },
      transition: {
        duration: 0.75,
        delay: 0,
        ease: 'anticipate',
      },
    },
    patternRedRight3: {
      animation: {
        default: {
          opacity: [1, 0],
        },
        clicked: {},
      },
      transition: {
        duration: 0.75,
        delay: 0,
        ease: 'anticipate',
      },
    },
    cloudRight1: {
      animation: {
        default: {
          y: [0, -33],
          x: [0, 100],
        },
        clicked: {},
      },
      transition: {
        duration: 0.75,
        delay: 0,
        ease: 'anticipate',
      },
    },

    cloudsHexagonal: {
      animation: {
        default: {
          opacity: [1, 0],
        },
        clicked: {},
      },
      transition: {
        duration: 0.75,
        delay: 0,
        ease: 'anticipate',
      },
    },
  },
} as const

const fadeOut = {
  clicked: { opacity: 0 },
  initial: {
    opacity: 1,
  },
}

const fadeIn = {
  clicked: { opacity: 1 },
  initial: {
    opacity: 0,
  },
}

export const pickupStationAnimation = {
  mainIcon: {
    animation: {
      clicked: {
        y: [0, -35, 0],
        transitionEnd: {
          minHeight: 'auto',
        },
      },
      initial: {},
    },
    duration: 1.3,
    delay: 0.65,
  },

  cloudLeft1: {
    animation: {
      clicked: { y: 380, x: 100 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  cloudLeft2: {
    animation: {
      clicked: { top: 280, left: -45 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  cloudLeft3: {
    animation: fadeOut,
    duration: 0.75,
    delay: 0,
  },
  cloudLeft4: {
    animation: fadeIn,
    duration: 0.75,
    delay: 0,
  },
  patternRedLeft2: {
    animation: fadeOut,
    duration: 0.75,
    delay: 0,
  },
  patternRedLeft3: {
    animation: {
      clicked: { x: 510, y: -400 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  patternRedLeft4: {
    animation: fadeIn,
    duration: 0.75,
    delay: 0,
  },
  circleRedLeft: {
    animation: {
      clicked: { x: -350, y: 150 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  cloudRight1: {
    animation: {
      clicked: { top: -40, right: -340 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  cloudRight2: {
    animation: {
      clicked: { top: 350, right: -350 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  patternRedRight1: {
    animation: {
      clicked: { top: 20, right: -370 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  patternRedRight2: {
    animation: fadeOut,
    duration: 0.75,
    delay: 0,
  },
  cloudsHexagonal: {
    animation: fadeOut,
    duration: 0.75,
    delay: 0,
  },
  deliveryLabel: {
    animation: {
      clicked: {
        y: -350,
      },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
}

export const pickupPointAnimation = {
  mainIcon: {
    animation: {
      clicked: {
        y: [0, 30, -30],
        transitionEnd: {
          minHeight: 'auto',
        },
      },
      initial: {},
    },
    duration: 1.3,
    delay: 0.65,
  },

  cloudLeft1: {
    animation: {
      clicked: { y: 38, x: 100 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  cloudLeft2: {
    animation: {
      clicked: { top: 280, left: -45 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  cloudLeft3: {
    animation: fadeOut,
    duration: 0.75,
    delay: 0,
  },
  cloudLeft4: {
    animation: fadeIn,
    duration: 0.75,
    delay: 0,
  },
  patternRedLeft2: {
    animation: fadeOut,
    duration: 0.75,
    delay: 0,
  },
  patternRedLeft3: {
    animation: {
      clicked: { x: 510, y: -400 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  patternRedLeft4: {
    animation: fadeIn,
    duration: 0.75,
    delay: 0,
  },
  circleRedLeft: {
    animation: {
      clicked: { x: -350, y: 150 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  cloudRight1: {
    animation: {
      clicked: { top: -40, right: -340 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  cloudRight2: {
    animation: {
      clicked: { top: 350, right: -350 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  patternRedRight1: {
    animation: {
      clicked: { top: 20, right: -370 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  patternRedRight2: {
    animation: fadeOut,
    duration: 0.75,
    delay: 0,
  },
  cloudsHexagonal: {
    animation: fadeOut,
    duration: 0.75,
    delay: 0,
  },
  deliveryLabel: {
    animation: {
      clicked: {
        y: -350,
      },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
}

export const courierAnimation = {
  mainIcon: {
    animation: {
      clicked: {
        y: [0, 15, -20],
        transitionEnd: {
          minHeight: 'auto',
        },
      },
      initial: {},
    },
    duration: 1.3,
    delay: 0.65,
  },

  cloudLeft1: {
    animation: {
      clicked: { y: 38, x: 100 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  cloudLeft2: {
    animation: {
      clicked: { top: 280, left: -45 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  cloudLeft3: {
    animation: fadeOut,
    duration: 0.75,
    delay: 0,
  },
  cloudLeft4: {
    animation: fadeIn,
    duration: 0.75,
    delay: 0,
  },
  patternRedLeft2: {
    animation: fadeOut,
    duration: 0.75,
    delay: 0,
  },
  patternRedLeft3: {
    animation: {
      clicked: { x: 510, y: -400 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  patternRedLeft4: {
    animation: fadeIn,
    duration: 0.75,
    delay: 0,
  },
  circleRedLeft: {
    animation: {
      clicked: { x: -350, y: 150 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  cloudRight1: {
    animation: {
      clicked: { top: -40, right: -340 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  cloudRight2: {
    animation: {
      clicked: { top: 350, right: -350 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  patternRedRight1: {
    animation: {
      clicked: { top: 20, right: -370 },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
  patternRedRight2: {
    animation: fadeOut,
    duration: 0.75,
    delay: 0,
  },
  cloudsHexagonal: {
    animation: fadeOut,
    duration: 0.75,
    delay: 0,
  },
  deliveryLabel: {
    animation: {
      clicked: {
        y: -350,
      },
      initial: {},
    },
    duration: 0.75,
    delay: 0,
  },
}

export const getDeliveryAnimation = (chosenDelivery: Delivery) => {
  if (chosenDelivery === 'pickupPoint') return pickupPointAnimation
  if (chosenDelivery === 'courier') return courierAnimation
  return pickupStationAnimation
}

export default CHOOSE_DELIVERY_VIEW_ANIMATION
