import { ViewAnimation } from 'types/view'
import BASE_ANIMATION from './baseView'

const FINAL_VIEW_ANIMATION: ViewAnimation = {
  mobile: {
    ...BASE_ANIMATION.mobile,
    whiteLinePattern: {
      animation: {
        default: {
          scale: [1, 1.15],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    cloudLeft1: {
      animation: {
        default: {
          x: [0, 20],
          y: [0, 10],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    cloudLeft2: {
      animation: {
        default: {
          x: [0, 20],
          y: [0, 10],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    cloudLeft3: {
      animation: {
        default: {
          x: [0, 20],
          y: [0, 10],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    patternRedLeft1: {
      animation: {
        default: {
          scale: [1, 1.25],
          x: [0, -58],
          y: [0, -45],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    cloudLeft4: {
      animation: {
        default: {
          x: [0, 20],
          y: [0, 10],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    patternRedLeft2: {
      animation: {
        default: {
          scale: [1, 2.2],
          x: [0, -260],
          y: [0, 85],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    patternRedLeft5: {
      animation: {
        default: {
          scale: [1, 1.4],
          rotate: [0, 165],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    circleLeft: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    circleRedLeft: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    circleRedRight: {
      animation: {
        default: {
          x: [0, 20],
          y: [0, 10],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    patternRedRight1: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    patternRedRight2: {
      animation: {
        default: {
          scale: [1, 1.45],
          x: [0, 55],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },

    patternRedRight3: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    cloudRight1: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    cloudRight2: {
      animation: {
        default: { x: [0, -85], y: [0, 50] },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    cloudsHexagonal: {
      animation: {
        default: {
          y: [0, -28],
          x: [0, 15],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
  },

  desktop: {
    ...BASE_ANIMATION.desktop,
    whiteLinePattern: {
      animation: {
        default: {
          scale: [1, 1.15],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    cloudLeft1: {
      animation: {
        default: {
          x: [0, 20],
          y: [0, 10],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    cloudLeft2: {
      animation: {
        default: {
          x: [0, 20],
          y: [0, 10],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    cloudLeft3: {
      animation: {
        default: {
          x: [0, 20],
          y: [0, 10],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    patternRedLeft1: {
      animation: {
        default: {
          scale: [1, 1.25],
          x: [0, -58],
          y: [0, -45],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    cloudLeft4: {
      animation: {
        default: {
          x: [0, 20],
          y: [0, 10],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    patternRedLeft2: {
      animation: {
        default: {
          scale: [1, 2.2],
          x: [0, -260],
          y: [0, 85],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    patternRedLeft5: {
      animation: {
        default: {
          scale: [1, 1.4],
          rotate: [0, 165],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    circleLeft: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    circleRedLeft: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    circleRedRight: {
      animation: {
        default: {
          x: [0, 20],
          y: [0, 10],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    patternRedRight1: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    patternRedRight2: {
      animation: {
        default: {
          scale: [1, 1.45],
          x: [0, 55],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },

    patternRedRight3: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    cloudRight1: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    cloudRight2: {
      animation: {
        default: { x: [0, -85], y: [0, 50] },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
    cloudsHexagonal: {
      animation: {
        default: {
          y: [0, -28],
          x: [0, 15],
        },
        clicked: {},
      },
      transition: {
        duration: 2.25,
        delay: 0,
        ease: 'anticipate',
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      },
    },
  },
}

export default FINAL_VIEW_ANIMATION
