const BASE_ANIMATION = {
  mobile: {
    pickupStationIcon: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    pickupPointIcon: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    courierIcon: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    whiteLinePattern: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    patternRedLeft1: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    patternRedLeft2: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    patternRedLeft5: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    patternRedRight1: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    patternRedRight2: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    patternRedRight3: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    cloudRight1: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    cloudRight2: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    cloudLeft1: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    cloudLeft2: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    cloudLeft3: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    cloudLeft4: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    circleLeft: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    circleRedLeft: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    circleRedRight: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    cloudsHexagonal: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
  },
  desktop: {
    pickupStationIcon: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    pickupPointIcon: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    courierIcon: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    whiteLinePattern: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    patternRedLeft1: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    patternRedLeft2: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    patternRedLeft5: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    patternRedRight1: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    patternRedRight2: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    patternRedRight3: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    cloudRight1: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    cloudRight2: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    cloudLeft1: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    cloudLeft2: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    cloudLeft3: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    cloudLeft4: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    circleLeft: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    circleRedLeft: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    circleRedRight: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
    cloudsHexagonal: {
      animation: {
        default: {},
        clicked: {},
      },
      transition: {},
    },
  },
}

export default BASE_ANIMATION
