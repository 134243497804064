import React from 'react'

import StartingView from 'components/layout/Views/StartingView'
import ChooseDeliveryView from 'components/layout/Views/ChooseDeliveryView'
import DeliveryBenefitsView from 'components/layout/Views/DeliveryBenefitsView'
import FinalView from 'components/layout/Views/FinalView'
import useView from 'hooks/useView'

const Views: React.FC = () => {
  const { currentView } = useView()

  return (
    <>
      {currentView === 'startingView' && <StartingView />}
      {(currentView === 'chooseDeliveryView' ||
        currentView === 'deliveryView') && <ChooseDeliveryView />}
      {currentView === 'deliveryBenefitsView' && <DeliveryBenefitsView />}
      {currentView === 'finalView' && <FinalView />}
    </>
  )
}

export default Views
