export const moveFirstItemToLast = <T>(arr: Array<T>) => {
  const clonedArr = [...arr]
  const firstItem = clonedArr.shift()
  if (firstItem) {
    clonedArr.push(firstItem)
  }
  return clonedArr
}
export const moveLastItemToFirst = <T>(arr: Array<T>) => {
  const clonedArr = [...arr]
  const lastItem = clonedArr.pop()
  if (lastItem) {
    clonedArr.splice(0, 0, lastItem)
  }
  return clonedArr
}
